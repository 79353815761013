<template>
<div class="help-block">
    <slot></slot>
</div>
</template>

<script>

</script>

<style scoped>
.help-block {
    background-color: white;
    width: 50vw;
    min-height: 300px;
    height: max-content;
    margin: 20px 0 0 0;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}
</style>