<template>
<div class="gui">
    <RouterView>
    </RouterView>
</div>
</template>

<script>
</script>

<style scoped>
.gui {
    display: flex;
    justify-content: center;
    margin-top: 140px;
}
</style>